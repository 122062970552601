import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import AnimationLink from "../components/nav/AnimationLink"
import { Container, Row, Col } from "react-grid-system"
import ProjectItem from "../components/works/ProjectItem"
import SEO from "../components/seo"
import { Header1 } from "../elements"
import TransitionContext from "../context/TransitionContext"

const Works = ({
  data: {
    allMdx: { edges: posts },
  },
  location,
  pageContext,
}) => {
  const { currentPage, numWorksPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numWorksPages
  const prevPage =
    currentPage - 1 === 1 ? `/works` : `works/${(currentPage - 1).toString()}`
  const nextPage = `/works/${(currentPage + 1).toString()}`
  return (
    <>
      <SEO
        title="Works - Featured Brand and Design Projects"
        description="Featured brand and design projects by PAKD Media. "
        pathname={location.pathname}
      />
      <TransitionContext location={location}>
        <Container fluid className="pagemargintop pagemarginbtm innerwrap">
          <Row justify="center" align="center">
            <Col xs={12}>
              <Header1 lineOne="Works" lineTwo="Geek out on the details." />
            </Col>
          </Row>

          <Row
            align="center"
            justify="center"
            gutterWidth={16}
            style={{ overflow: `hidden` }}
          >
            {posts.map((post, index) => (
              <Col
                md={4}
                sm={6}
                key={post.node.fields.slug}
                id={post.node.fields.slug + index}
              >
                <ProjectItem
                  post={post}
                  index={index}
                  aria-label={` Visit post ${post.node.title} from PAKD Media`}
                  subpath={`/works${post.node.frontmatter.slug}`}
                  state={{ fromWorksPage: true }}
                  title={post.node.title}
                />
              </Col>
            ))}
          </Row>
          <Row align="center" justify="center">
            {!isFirst && (
              <AnimationLink
                to={prevPage}
                ariaLabel="Previous Posts"
                rel="prev"
                className="blogloader"
              >
                ← Newer Works
              </AnimationLink>
            )}
            {!isLast && (
              <AnimationLink
                to={nextPage}
                ariaLabel="Next Posts"
                rel="next"
                className="blogloader"
              >
                Older Works →
              </AnimationLink>
            )}
          </Row>
        </Container>
      </TransitionContext>
    </>
  )
}

export default Works

Works.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query Projects($limit: Int!, $skip: Int!, $tags: [String!]) {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          type: { eq: "works" }
          draft: { eq: false }
          tags: { in: $tags }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            clientName
            projectTitle
            slug
            date
            tags
            homehero {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            itemColor
          }
        }
      }
    }
  }
`
